.date-picker-container {
  position: relative;
  margin-left: 2rem;
  z-index: 999999999;
  width: 20rem;
  display: flex;
  align-items: center;
}

.inline-block {
  display: inline-block;
}
