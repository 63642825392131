.createForm.bootstrap-inside *,
.flatpickr-calendar * {
  font-size: 1.6rem;
  font-family: 'Circular Std Bold';
}

.createForm.bootstrap-inside .fa {
  font: 14px FontAwesome;
}

.createForm.bootstrap-inside table {
  /* width: 100%; */
}

.createForm.bootstrap-inside .table-responsive {
  /* overflow-y: hidden; */
  overflow-x: visible;
}
/* .bootstrap-inside .table-responsive {
} */

.bootstrap-inside .table-bordered > :not(caption) > * {
  border-width: 0px;
}

.bootstrap-inside .list-group-item {
  background-color: transparent;
}

.createForm.bootstrap-inside .table > :not(:first-child) {
  border-top: 0px;
}

.createForm.bootstrap-inside input {
  /* width: 100%; */
  /* margin-top: 1rem; */
  /* display: inline; */
  background-color: transparent;
  box-shadow: inset 2px 2px 4px #0000001a, inset -2px -2px 4px #ffffff69;
  border-radius: 1rem;
  opacity: 1;
  outline: none;
  border: 0px;
  height: 6rem;
  /* width: 100%; */
  /* height: 6rem; */
  /* padding-left: 2.1rem;
  padding-right: 50px; */
}
.createForm.bootstrap-inside input:focus {
  background-color: transparent;
  outline: none;
}

.createForm.bootstrap-inside input:checked[type='checkbox']:focus {
  background-color: #4f4ed0;
}

.createForm.bootstrap-inside input[type='checkbox'] {
  height: 2rem;
}
.createForm.bootstrap-inside input[type='checkbox'] {
  outline: none;
}

.createForm.bootstrap-inside .formio-component-select input {
  height: auto;
}

.createForm.bootstrap-inside label {
  color: #717171;
}

.col-form-label::after {
  content: ':';
}

/* .createForm .formio-component-textfield {
  display: flex;
  align-items: center;
  width: 100%;
} */

.form-control.ui.fluid.selection.dropdown {
  /* width: 100%; */

  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 4px inset, rgba(255, 255, 255, 0.41) -2px -2px 4px inset;

  color: black;
  min-height: 45px;
  border: 0px;
  border-radius: 1rem;
  /* padding: 1rem; */
}

/* .form-group > .col-form-label {
  margin: 0 10px;
} */

/* .createForm .formio-component-textfield input {
  margin-left: 2rem;
  width: 80%;
} */

.createForm.bootstrap-inside textarea:focus {
  outline: none;
}

.createForm.bootstrap-inside textarea {
  /* width: 100%; */
  border: none;
  background-color: transparent;
  box-shadow: inset 2px 2px 4px #0000001a, inset -2px -2px 4px #ffffff69;
  border-radius: 1rem;

  padding: 1rem;
  color: black;
  min-height: 45px;
}

.createForm.bootstrap-inside .sr-only {
  /* display: none; */
}

.createForm.bootstrap-inside .btn {
  padding: 1rem 1.5rem;
  font-size: 2rem;
  min-width: 20rem;
  font-family: Circular Std Medium;
  text-transform: capitalize;
  display: inline;
  text-align: 'center';
  border-radius: 5px;
  border: 0px;
  box-shadow: -5px -5px 15px #ffffffcb, 5px 5px 10px #0000000d;
  cursor: pointer;
  /* margin-top: 2rem; */
}

.createForm.bootstrap-inside .editgrid-actions .btn {
  padding: 1rem;
  font-size: 1.5rem;
  min-width: 15rem;
}

.createForm.bootstrap-inside .btn-primary {
  font-family: Circular Std Medium;
  background-color: #4f4ed0;
  color: white;
}
.createForm.bootstrap-inside .required:after {
  content: '';
}

.createForm .alert {
  display: none;
}

.createForm.bootstrap-inside .col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  overflow: hidden;
  text-overflow: ellipsis;
}
