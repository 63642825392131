.input{
  border: 3rem;
  width: 100%;
  height: min(45px, 100%);
  padding-left: 2.1rem;
  padding-right: 2rem;
}

.input .react-datetime-picker__wrapper{
  border-radius: 1rem;
  width: 100%;
}